import React from "react";

const page = (
   <div className="info">
        <img src="me-bo-tie.jpg" />
        <h1>Hardus Havenga</h1>
        <h3>Information Technology Specialist</h3>
   </div>
);

const Info = () => {
    return (<div>{page}</div>);
}

export default Info;