import React from "react";

const page = (
    <div className="interests">
        <h1>Interests</h1>
            <p>At the forefront of my life is my faith and my relationship with Jesus Christ. It is through this lens that I view and participate in the world around me. As a devout believer, I am actively involved in the Christian Motorcyclists Association (CMA) as the president of our local chapter. Combining my love for motorcycles with my commitment to my faith is a true blessing. Together with fellow riders who share similar values, we engage in meaningful activities that reflect our dedication to Christ and make a positive impact on others' lives.</p>

            <p>Outside of my spiritual journey, I find solace and inspiration in the world of technology and business. Tinkering with electronics is one of my greatest joys. Exploring the inner workings of various devices, experimenting with circuits, and pushing the boundaries of innovation bring me a sense of curiosity and excitement.</p>

            <p>Living on a picturesque farm allows me to immerse myself in the beauty of the outdoors. Nature is my sanctuary, providing a tranquil backdrop for relaxation and reflection. Caring for the land and the animals that call it home is a responsibility I cherish, as it connects me to the rhythms of the earth and fosters a deep appreciation for the natural world. Additionally, camping adventures with my family are treasured moments that strengthen our bonds and create lasting memories amidst the wonders of creation.</p>

            <p>Animals hold a special place in my heart, and I delight in their companionship. Whether it's playing with our beloved pets or caring for the animals on our farm, their presence brings joy and a reminder of the beauty of God's creation.</p>

            <p>Above all, my most profound moments are the ones shared with my incredible wife and children. Family is the cornerstone of my life, and I prioritize nurturing and fostering our relationships. Engaging in meaningful activities, having heartfelt conversations, and simply enjoying the love and support we have for one another are the truest reflections of happiness and fulfillment.</p>

            <p>In the pockets of time that I carve out, I dedicate myself to personal IT projects that align with my passions and aspirations. Developing mobile apps, creating websites, and exploring emerging technologies in a sandbox environment allow me to unleash my creativity and apply my skills in innovative ways. These projects ignite my passion and enable me to contribute to the world through the gifts I've been given.</p>

            <p>Join me on this incredible journey, where faith intertwines with technology, nature, family, and personal growth. Together, we can navigate the diverse paths of life, inspired by the love of God and the infinite possibilities that lie before us.</p>
    </div>
);

const Interests = () => {
    return (<>{page}</>);
}

export default Interests;