import './style.css';
import Info from './components/Info';
import About from './components/About';
import Interests from './components/Interestes';
import Footer from './components/Footer'

function App() {
  return (
    <table className='main--table'>
      <tr><td> <Info /> </td></tr>
      <tr><td> <About /></td></tr>
      <tr><td> <Interests /></td></tr>
      <tr><td> <Footer /></td></tr>
    </table>
  );
}

export default App;
