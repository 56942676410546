import React from "react";

const Footer = () => {
    return (
      <footer className="footer">
          <small>
              &copy; 2023 Havenga development. All rights reserved.
          </small>
      </footer>
    )
  }

  export default Footer;